//Crosstab

.ct__filters {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  align-items: baseline;

  .filter-item {
    padding: 15px 20px;
    flex: 1 1 0;

    .ui-inputgroup {
      float: right;
    }
  }
}

.ct__topcontrols {
  float: left;

  li {
    display: inline-block;
    margin-right: 5px;
  }
}

.crosstab__wrapper {
  @include clearfix();
  clear: both;

  .content {
    padding: 20px 10px;
    @include clearfix();

    .content__col {
      @include span(12);
      min-height: 500px;

      &.col--left {
        @include span(4);
      }

      &.col--right {
        @include span(8);
      }
    }
  }
}

.defer-row {
  clear: both;
  font-size: 14px;
  padding: 10px 0;

  p-checkbox {
    display: inline-block;
    padding: 7px 0 0 0;

    .p-checkbox {
      margin-top: -1px;
    }
  }

  .btn {
    float: right;
  }
}

.ct__footer {
  border-top: 1px solid #ebebeb;
  padding: 15px;
  margin: 0 0 0;
  position: relative;
  background: #f9fafc;
  text-align: right;
  border-radius: 0 0 3px 3px;

  .btn {
    margin: 0 5px 0 0;
  }
}

.default-content {
  p {
    margin-bottom: 20px;
  }
}

.default {
  float: left;
  width: 100%;
  margin-bottom: 10px;

  .default__filters {
    float: left;
    width: 100%;
    padding: 0;

    ul {
      display: block;
      width: 100%;

    }

    li {
      float: left;
      text-align: left;
      border: 1px solid #ebebeb;
      overflow: hidden;
      border-right: 0;

      &:first-child {
        @include border-radius-separate(3px, 0, 0, 3px);
      }

      &:last-child {
        border-right: 1px solid #ebebeb;
        @include border-radius-separate(0, 3px, 3px, 0);
      }

      a {
        color: #666;
        font-size: 14px;
        padding: 10px 25px;
        display: block;

        &:hover {
          color: #666;
          background: #f3f6f9;
        }
      }

    }
  }
}

.dx-loadpanel-content {
  z-index: 900 !important;
}

.ui-inputgroup {
  .p-dropdown-label {
    position: relative;
  }

  .label-text {
    padding-right: 50px;
  }

  .btn-group {
    position: absolute;
    right: 24px;
    top: 0;

    .p-button.p-button-icon-only {
      width: 26px;
      background: none;
      border-radius: 0;
      border: none;
      color: #b3b3b3;
      font-size: 14px;
      line-height: 32px;
      padding: 0;

      .p-button-label {
        padding: 0;
        line-height: 32px;
      }
    }
  }

  .p-dropdown-item {
    position: relative;
    padding: 9px 10px 8px !important;

    &:first-child {
      border-top: 0;
    }

    .btn-group {
      right: 0;

      .p-button.p-button-icon-only {
        font-size: 13px;
      }
    }
  }
}

.dx-pivotgrid-container {
  overflow-x: auto;
  width: 100.1%;
}

._checkbox_disabled {
  pointer-events: none;

  .dx-checkbox-icon {
    background: #ddd;
  }
}

div[group="filter"] {
  .dx-sort.dx-sort-up {
    display: none;
  }
}

.dx-pivotgrid.dx-overflow-hidden {
  overflow: initial !important;
}

// insight

.insight__filters {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  justify-content: end;

  .filter-item {
    padding: 15px 20px;

  }
}


.insight__wrapper {
  @include clearfix();
  clear: both;

  .content {
    padding: 20px 10px;
    margin: 0;
    @include clearfix();

    .content__col {
      @include span(12);
      min-height: 500px;

      &.col--left {
        @include span(4);
      }

      &.col--right {
        @include span(8);
      }
    }
  }
}