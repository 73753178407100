// Dark side of the site.

// Dependencies:
//@import "lib/config"; // framework config

@import "lib/mixins/mixins"; // mixins



// Susy
@import "vendor/susy";

// Breakpoint
@import "vendor/breakpoint";

@import "lib/reset"; // base - general styles

/**
 * App Configuration
 */

@import "app/app__config"; // breakpoints - and other settings which aint related to theming
@import "vendor/baseline/baseline";
//primt-ng

@import "vendor/primeng/theme/theme";
@import "vendor/primeng/primeng";


// // Start app styles here:
@import "app/layout"; // positioning of modules - as per SMACSS approach
@import "app/base"; // base styles
@import 'app/forms'; // forms styles
@import "app/grid";
//@import 'app/login';

// Blocks
@import "app/header";

// // Modules:
@import "modules/tabs";
@import "modules/arrows";
@import "modules/breadcrumbs";
@import "modules/filters";
@import "modules/tour";
@import "modules/timeline";
@import "modules/customized__dashboard";
//@import 'modules/date__picker';
//@import 'modules/_date__picker__bootstrap';
@import "modules/toggle";
@import "app/table";
//@import 'app/pagination';
//@import 'app/buttons';
//@import 'app/modal'; 
@import 'app/twofactor';

//Vendors
//@import 'vendor/flags/flags';
@import "vendor/roboto";
@import "vendor/gilory";
@import "vendor/fontawesome/fontawesome";
@import "vendor/fontawesome/light";
@import "vendor/fontawesome/solid";
@import "vendor/fontawesome/regular";
@import "vendor/fontawesome/brands";
@import "vendor/fontawesome/family-declaration";
@import "app/messages";

//Widgets
@import "app/widget";
@import "app/kpi";
@import "app/pages/resources";
@import "app/pages/trainingvideos";
@import "app/pages/library";

@import "app/widgets/areas__for__improvement";
@import "app/widgets/multiple__widget";
@import "app/widget__filters";
@import "app/edit__translation";
@import "app/widgets/total__alerts";
@import 'app/pages/performance__trend';
@import 'app/pages/identity';

@import 'app/widgets/top__bottom';
@import 'app/pages/report__generator';
@import 'app/widgets/competion__rate';
@import 'app/widgets/cej_chart.scss';
@import 'app/widgets/key__performance__indicators';
@import 'app/widgets/customer__comments';
/*@import 'app/widgets/exprience__kpi';
@import 'app/widgets/last__days';
@import 'app/widgets/weekly__traffic';
@import 'app/widgets/alerts';
@import 'app/widgets/sales__driver';
@import 'app/widgets/surveys';
@import 'app/widgets/recent__comments';

@import 'app/widgets/areas__for__improvement';
@import 'app/widgets/tags';
@import 'app/widgets/cl__breakdown';
@import 'app/widgets/cl__efficiency';
@import 'app/widgets/obw';
@import 'app/widgets/retailers';
@import 'app/widgets/cl__re';
@import 'app/widgets/emerging__trends';
@import 'app/widgets/customer__inquiries';
@import 'app/widgets/auto__reviews';
@import 'app/widgets/top__issues';
@import 'app/widgets/top__bottom';
@import "app/widgets/response__analysis";
@import "app/widgets/multiple__widget";
@import "app/widgets/multiple__hot__alerts";
@import "app/widgets/total__alerts";
@import "app/widgets/sentiment__analysis";

@import "app/widgets/social__media__feed";
@import "app/widgets/social__media__cards";
@import "app/widgets/keywords";
@import "app/widgets/categories";

@import 'app/kpi';
@import 'app/kpi__tabular';

@import 'app/messages';
@import 'app/table__tabs';*/
@import "app/loader";

//Pages
//@import 'app/pages/social__media';
//@import 'app/pages/action__planner';
//@import 'app/pages/text__analytics';
//@import 'app/pages/report__generator';
//@import 'app/pages/content__repository';
@import 'app/pages/error';
@import 'app/pages/customer__profile';
//@import 'app/pages/customer__rating';
@import 'app/pages/cross__tab';
//@import 'app/pages/performance__trend';
//@import 'app/pages/manage__user';
//@import 'app/pages/survey__list';
//@import 'app/pages/rating__analysis';
//@import 'app/pages/trend__report';
//@import 'app/pages/contact__statistics';
//@import 'app/pages/comment__list';
//@import "app/pages/notification__template";
//@import "baseline/baseline";
@import "app/pages/notification__template";
@import "app/sidebar";
@import 'app/gridview';
@import 'app/text-analytics/dynamicsearch';
@import 'app/text-analytics/comment__list';


//social media
@import 'app/social-media/sm__kpi';
@import 'app/social-media/rating__distribution';
@import 'app/social-media/dealer__profile';
@import 'app/social-media/review__list';

// text analytics
@import 'app/text-analytics/tree__report';
@import 'app/text-analytics/comment__list';
@import "app/text-analytics/sentiment__count";
@import 'app/text-analytics/treding__topics';
@import 'app/text-analytics/category__correlation';
@import 'app/text-analytics/catfrequency';
@import 'app/text-analytics/category__time';
@import 'app/text-analytics/dynamicsearch';
@import 'app/text-analytics/categories';
@import 'app/text-analytics/keywords';
@import 'app/text-analytics/review-detail';

@import 'app/report-generator/override';
@import 'app/primeng/override';

.print_only {
    display: none;
}

@import 'app/extreme-editor';
@import 'app/survey-preview/survey-preview';

// @import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';


// .owl-dots{
//     position: absolute;
//     bottom:20px;
// }

.flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-space-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.crop--images .main {
    display: block;
}

.crop--images .main {
    display: block !important;
}

.circular-image {
    border-radius: 50%
}